import React, { useState, useEffect } from "react";

const SourcePopup = ({
  title,
  authors,
  publicationDate,
  publicationType,
  abstract,
  pmid,
  isSourceCollapsed, // Receive the state here
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [leftPosition, setLeftPosition] = useState("50%");

  const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;

  const generateCitation = () => {
    return `${authors}. "${title}." *${publicationType}*, ${publicationDate}. PubMed, ${pubmedUrl}.`;
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(generateCitation().replace(/\*/g, ""))
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
  };

  useEffect(() => {
    const calculateLeftPosition = () => {
      if (isSourceCollapsed) {
        setLeftPosition("50%"); // Center when collapsed
      } else {
        const sourceSectionWidth = 300; // Adjust this based on your actual source section width
        const windowWidth = window.innerWidth;
        const newLeftPosition = `${(windowWidth - sourceSectionWidth) / 2}px`;
        setLeftPosition(newLeftPosition);
      }
    };

    calculateLeftPosition();
    window.addEventListener("resize", calculateLeftPosition);

    return () => window.removeEventListener("resize", calculateLeftPosition);
  }, [isSourceCollapsed]);

  return (
    <div
      className="bg-[#1F2937] text-white w-[600px] mx-auto max-h-[85vh] overflow-y-auto rounded-lg shadow-lg p-6 relative flex flex-col space-y-4"
      style={{
        position: "fixed",
        top: "50%",
        left: leftPosition, // Dynamically set the left position
        transform: "translate(-50%, -50%)",
      }}
    >
      <a
        href={pubmedUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="text-xl font-semibold mb-2 hover:underline"
        style={{ color: "#ffc77d" }}
      >
        {title}
      </a>
      <div className="space-y-4 flex-grow">
        <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
          <strong>Authors:</strong>
          <p>{authors}</p>
        </div>

        <div className="flex space-x-3">
          <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Publication Date:</strong>
            <p>{publicationDate}</p>
          </div>
          <div className="flex-1 p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
            <strong>Publication Type:</strong>
            <p>
              {Array.isArray(publicationType)
                ? publicationType.join(", ") // If it's an array, join with commas and spaces
                : publicationType}{" "}
              {/* If it's already a string */}
            </p>
          </div>
        </div>

        <div className="p-3 bg-gray-700 rounded-lg shadow-inner text-sm">
          <strong>Abstract:</strong>
          <p>{abstract}</p>
        </div>

        <div
          className="p-4 bg-gray-700 rounded-lg shadow-inner cursor-pointer hover:bg-gray-600 transition-colors"
          onClick={handleCopy}
        >
          <strong style={{ color: "#ffc77d" }}>Citation (MLA):</strong>
          <div className="relative mt-2">
            <div
              className="ml-4 text-sm whitespace-pre-wrap"
              style={{
                lineHeight: "2", // Double spacing for MLA
                textIndent: "-40px", // Hanging indent for second and subsequent lines
                paddingLeft: "40px", // Indent the entire block
              }}
            >
              <p>
                {authors}. “{title}.”
              </p>
              <p className="ml-6">
                <span className="italic">
                  {Array.isArray(publicationType)
                    ? publicationType.join(", ") // If it's an array, join with a comma and space
                    : publicationType}{" "}
                  {/* If it's already a string */}
                </span>
                , {publicationDate}. <span className="italic">PubMed</span>,{" "}
                <a href={pubmedUrl} target="_blank" rel="noopener noreferrer">
                  {pubmedUrl}
                </a>
                .
              </p>
            </div>
          </div>
          <div className="text-xs mt-2 text-gray-400">
            {isCopied ? "Citation copied to clipboard!" : "Click to copy"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourcePopup;
