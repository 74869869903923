import React, { useState, useCallback } from "react";
import ErrorPopup from "./ErrorPopup";
import InputSection from "./InputSection";

const Controller = ({ handleNewQuestion, updateResponse }) => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchQuestionAnswer = useCallback(
    (question, responseIndex) => {
      console.log("fetchQuestionAnswer: Starting to fetch question answer");

      const encodedQuery = encodeURIComponent(question);
      const eventSource = new EventSource(
        `${process.env.REACT_APP_API_URL}/question_answer_stream?query=${encodedQuery}`
      );

      eventSource.onopen = () => {
        console.log("fetchQuestionAnswer: Stream opened");
        setIsLoading(true); // Set loading to true when the stream opens
      };

      eventSource.onmessage = (event) => {
        if (event.data.startsWith("[")) {
          try {
            const jsonData = JSON.parse(event.data);
            if (Array.isArray(jsonData)) {
              console.log(
                "fetchQuestionAnswer: Received final JSON response with sources"
              );
              updateResponse(responseIndex, jsonData, true);
              setIsLoading(false); // Stop loading when the final response is received
              eventSource.close();
            }
          } catch (error) {
            console.error("fetchQuestionAnswer: Error parsing JSON:", error);
            setIsError(true);
            setIsLoading(false); // Stop loading on error
            eventSource.close();
          }
        } else {
          console.log("fetchQuestionAnswer: Received text chunk");
          updateResponse(responseIndex, event.data);
        }
      };

      eventSource.onerror = (error) => {
        console.error(
          "fetchQuestionAnswer: Stream encountered an error:",
          error.message || error
        );
        setIsError(true);
        setIsLoading(false); // Stop loading on error
        eventSource.close();
      };
    },
    [updateResponse]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handleSubmit: Submitting new question");

    setIsLoading(true); // Immediately set loading true on submission
    const newResponseIndex = handleNewQuestion(query);
    setQuery("");

    // Perform fetch
    fetchQuestionAnswer(query, newResponseIndex);
  };

  return (
    <>
      <div
        className="bg-gray-900 p-6 rounded-lg"
        style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <InputSection
          query={query}
          setQuery={setQuery}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
        {isError && (
          <ErrorPopup message="An error occurred while processing your request. Please try again." />
        )}
      </div>
    </>
  );
};

export default Controller;
