import React, { useState } from "react";

const MAX_TITLE_LENGTH = 50;
const MAX_AUTHORS_LENGTH = 50;

const SourceCard = ({
  title,
  authors,
  publicationDate,
  publicationType,
  abstract,
  openSourcePopup,
  pmid,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [showMoreTooltip, setShowMoreTooltip] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const displayPublicationType = Array.isArray(publicationType)
    ? publicationType.join(", ")
    : publicationType;

  const formatAuthors = (authors) => {
    const authorList = authors.split(", ");
    if (authorList.length > 3) {
      return `${authorList[0]}, et al.`;
    }
    return authorList
      .map((author) => {
        const [lastName, firstName] = author.split(" ");
        return `${lastName}, ${firstName}`;
      })
      .join(", ");
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${day} ${new Date(date).toLocaleString("default", {
      month: "long",
    })} ${year}`;
  };

  const copyFormattedCitationToClipboard = (e) => {
    e.stopPropagation(); // Prevent triggering card expansion when copying citation
    const formattedAuthors = formatAuthors(authors);
    const formattedDate = formatDate(publicationDate);
    const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;

    const formattedCitation = `${formattedAuthors}. "${title}." ${displayPublicationType}, ${formattedDate}, ${pubmedUrl}.`;

    navigator.clipboard
      .writeText(formattedCitation)
      .then(() => {
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000); // Adjusted timeout for better user experience
      })
      .catch((err) => console.error("Failed to copy citation: ", err));
  };

  const truncatedTitle =
    title.length > MAX_TITLE_LENGTH
      ? `${title.substring(0, MAX_TITLE_LENGTH)}...`
      : title;
  const truncatedAuthors =
    authors.length > MAX_AUTHORS_LENGTH
      ? `${authors.substring(0, MAX_AUTHORS_LENGTH)}...`
      : authors;

  const handleCardClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 200); // Reset the click animation after 200ms
    openSourcePopup({
      title,
      authors,
      date_published: publicationDate,
      publication_type: publicationType,
      abstract,
      pmid,
    });
  };

  const pubmedUrl = `https://pubmed.ncbi.nlm.nih.gov/${pmid}/`;

  return (
    <div
      className={`source-card relative mb-0 cursor-pointer bg-gray-700 rounded-lg p-4 shadow-md hover:shadow-lg transition-transform transform ${
        isClicked ? "scale-95" : ""
      }`}
      onClick={handleCardClick}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <a
            href={pubmedUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-lg text-orange-400 hover:underline"
            onClick={(e) => e.stopPropagation()}
          >
            {truncatedTitle}
          </a>
          <p className="text-sm text-gray-300">{truncatedAuthors}</p>
        </div>
        <div className="flex items-center justify-between mt-2 text-gray-400">
          <p className="text-sm">{publicationDate}</p>
          <div className="flex items-center">
            <button
              onClick={copyFormattedCitationToClipboard}
              onMouseEnter={() => setShowCopyTooltip(true)}
              onMouseLeave={() => setShowCopyTooltip(false)}
              className="mr-2 p-2 text-white hover:text-orange-400 transition-colors"
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/paste-clipboard.svg`}
                alt="Copy to clipboard"
              />
            </button>
            <button className="text-xs px-3 py-1.5 bg-gradient-to-r from-orange-400 to-orange-600 text-white rounded-full hover:bg-gradient-to-br focus:ring-2 focus:ring-orange-300 transition-all">
              More
            </button>
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <div
          className="absolute bottom-9 right-1 flex items-center w-250 max-w-xs p-2 mb-4 text-sm rounded-lg shadow text-gray-400 bg-gray-900"
          role="alert"
        >
          <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-lg text-green-300">
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM7.707 9.293a1 1 0 00-1.414 1.414L9 13.414l4.293-4.293a1 1 0 00-1.414-1.414L9 10.586l-1.293-1.293z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <div className="ml-2">Citation copied to clipboard.</div>
        </div>
      )}
    </div>
  );
};

export default SourceCard;
