import React, { useState, useEffect, useRef } from "react";
import SourceCard from "./SourceCard";

const SourceSection = ({
  sources,
  openSourcePopup,
  isCollapsed,
  setIsCollapsed,
  selectedResponseIndex,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sourcesPerPage, setSourcesPerPage] = useState(3);
  const sectionRef = useRef(null);
  const totalPages = Math.ceil(sources.length / sourcesPerPage);

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const goToPrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  useEffect(() => {
    const handleResize = () => {
      if (sectionRef.current) {
        const sectionHeight = sectionRef.current.clientHeight;
        const estimatedCardHeight = 200;
        const newSourcesPerPage = Math.max(
          1,
          Math.floor(sectionHeight / estimatedCardHeight)
        );
        setSourcesPerPage(newSourcesPerPage);
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === "ArrowRight" && currentPage < totalPages) {
        goToNextPage();
      } else if (event.key === "ArrowLeft" && currentPage > 1) {
        goToPrevPage();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentPage, totalPages]);

  const indexOfLastSource = currentPage * sourcesPerPage;
  const indexOfFirstSource = indexOfLastSource - sourcesPerPage;
  const currentSources = sources.slice(indexOfFirstSource, indexOfLastSource);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const formattedIndex = (selectedResponseIndex + 1)
    .toString()
    .padStart(2, "0");

  return (
    <>
      {/* Open Button (Visible when collapsed) */}
      {isCollapsed && (
        <button
          onClick={toggleCollapse}
          className="fixed top-1/2 right-0 transform -translate-y-1/2 z-50 p-3 bg-gray-700 text-white rounded-l-lg shadow-lg hover:bg-gray-600 focus:outline-none"
        >
          <div className="space-y-1">
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
            <div className="w-6 h-0.5 bg-white"></div>
          </div>
        </button>
      )}
      {/* Source Section (Visible when expanded) */}
      <div
        ref={sectionRef}
        className={`fixed top-0 right-0 z-40 transition-transform duration-300 bg-gray-800 shadow-lg h-screen w-80 overflow-y-auto ${
          isCollapsed ? "translate-x-full" : "translate-x-0"
        } rounded-l-lg border-l border-gray-700`}
      >
        <div className="flex justify-between items-center pl-4 pt-4 pr-4">
          <div className="text-lg font-semibold text-white">
            #{formattedIndex}
          </div>
          <h5
            id="drawer-navigation-label"
            className="text-lg font-semibold text-center flex-grow text-white uppercase"
          >
            Sources
          </h5>
          <button
            onClick={toggleCollapse}
            className="text-white p-2 rounded focus:outline-none hover:bg-gray-700"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="p-4 space-y-4">
          {currentSources.map((source, index) => (
            <SourceCard
              key={index}
              title={source.title}
              authors={source.authors}
              publicationDate={source.date_published}
              publicationType={source.publication_types}
              abstract={source.abstract}
              pmid={source.pmid}
              openSourcePopup={openSourcePopup}
            />
          ))}
        </div>

        <div className="pagination-controls fixed bottom-0 left-0 right-0 pb-4 mt-2 bg-gray-800">
          <div className="flex justify-center items-center space-x-4">
            {currentPage > 1 && (
              <button
                onClick={goToPrevPage}
                className="pagination-arrow text-gray-400 hover:text-white"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </button>
            )}

            <span className="page-number text-white">
              Page {currentPage} of {totalPages}
            </span>

            {currentPage < totalPages && (
              <button
                onClick={goToNextPage}
                className="pagination-arrow text-gray-400 hover:text-white"
              >
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="currentColor"
                >
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceSection;
