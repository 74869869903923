import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Modal from "react-modal";
import "./App.css";
import Controller from "./Controller";
import Output from "./Output";
import SourceSection from "./SourceSection";
import SourcePopup from "./SourcePopup";
import Header from "./Header";
import Placeholder from "./Placeholder";
import Login from "./Login";
import Settings from "./Settings";
import Disclaimer from "./Disclaimer";
import axios from "axios";
import { Analytics } from "@vercel/analytics/react";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [qaList, setQaList] = useState([]);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(null);
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState(false);
  const [sourcePopupInfo, setSourcePopupInfo] = useState(null);
  const [isSourceCollapsed, setIsSourceCollapsed] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isError, setIsError] = useState(false);

  const checkUserAccess = useCallback(async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USERS_API}/check_access/${email}`
      );
      return response.data;
    } catch (error) {
      console.error("User access check error:", error);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchUserInfoAndCheckAccess = async () => {
      try {
        if (isAuthenticated && !userInfo) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            if (!localStorage.getItem("userInfo")) {
              const response = await axios.get(
                `${
                  process.env.REACT_APP_USERS_API
                }/get_user_info/${encodeURIComponent(userEmail)}`
              );
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              setUserInfo(response.data);
            }

            const accessData = await checkUserAccess(userEmail);
            if (!accessData.access) {
              window.location.href = "https://www.askcure.ai/login";
            }
          }
        }
      } catch (error) {
        console.error("Error on initial load:", error);
        setIsError(true);
      }
    };

    fetchUserInfoAndCheckAccess();
  }, [isAuthenticated, userInfo, checkUserAccess]);

  const handleNewQuestion = (question) => {
    const newResponseIndex = qaList.length;
    setQaList((prevList) => [
      ...prevList,
      { question: question, answer: "", sources: [] },
    ]);
    setSelectedResponseIndex(newResponseIndex);

    setIsError(false);
    return newResponseIndex;
  };

  const updateResponse = (index, data, isSource = false) => {
    setQaList((prevList) => {
      const newList = [...prevList];
      if (isSource) {
        newList[index] = { ...newList[index], sources: data };
      } else {
        newList[index] = {
          ...newList[index],
          answer: newList[index].answer + data,
        };
      }
      return newList;
    });
  };

  const openSourcePopup = (sourceInfo) => {
    setIsSourcePopupOpen(true);
    setSourcePopupInfo({
      title: sourceInfo.title,
      authors: sourceInfo.authors,
      publicationDate: sourceInfo.date_published,
      publicationType: sourceInfo.publication_type,
      abstract: sourceInfo.abstract,
      pmid: sourceInfo.pmid,
    });
  };

  const closeSourcePopup = () => {
    setIsSourcePopupOpen(false);
  };

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const handleResize = () => {
      const newSize = window.innerWidth;
      setWindowWidth(newSize);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        if (isAuthenticated && !localStorage.getItem("userInfo")) {
          const userEmail = localStorage.getItem("userEmail");
          if (userEmail) {
            const response = await axios.get(
              `${
                process.env.REACT_APP_USERS_API
              }/get_user_info/${encodeURIComponent(userEmail)}`
            );
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            setUserInfo(response.data);
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
        setIsError(true);
      }
    };
    fetchUserInfo();
  }, [isAuthenticated]);

  const authenticateUser = (isAuth) => {
    setIsAuthenticated(isAuth);
    localStorage.setItem("isLoggedIn", isAuth);
  };

  return (
    <Router>
      <div className="App bg-gray-900 min-h-screen flex flex-col justify-between">
        <Header />
        <main className="flex-1 flex flex-col justify-between">
          <Analytics />
          <Routes>
            <Route
              exact
              path="/"
              element={
                isAuthenticated ? (
                  <div className="flex flex-1">
                    <div
                      className={`flex flex-col w-full p-4 transition-width duration-300 space-y-4 ${
                        isSourceCollapsed ? "" : "md:w-3/4"
                      }`}
                    >
                      {qaList.length === 0 ? (
                        <div className="flex-1 flex flex-col justify-center items-center">
                          <Placeholder />
                          <div className="mt-4 w-full md:w-3/4 mx-auto">
                            <Controller
                              handleNewQuestion={handleNewQuestion}
                              updateResponse={updateResponse}
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {qaList.map((qa, index) => (
                            <div key={index} className="mb-4">
                              <Output
                                question={qa.question}
                                answer={qa.answer}
                                index={index}
                                setSelectedResponseIndex={
                                  setSelectedResponseIndex
                                }
                              />
                              {index === qaList.length - 1 && (
                                <div className="mt-4 w-full md:w-3/4 mx-auto">
                                  <Controller
                                    handleNewQuestion={handleNewQuestion}
                                    updateResponse={updateResponse}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                    <SourceSection
                      sources={
                        selectedResponseIndex !== null
                          ? qaList[selectedResponseIndex].sources
                          : []
                      }
                      openSourcePopup={openSourcePopup}
                      isCollapsed={isSourceCollapsed}
                      setIsCollapsed={setIsSourceCollapsed}
                      selectedResponseIndex={selectedResponseIndex}
                    />
                  </div>
                ) : (
                  <Navigate to="/login" replace />
                )
              }
            />
            <Route
              path="/login"
              element={<Login authenticateUser={authenticateUser} />}
            />
            <Route path="/settings" element={<Settings />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
          </Routes>
        </main>
        <Modal
          isOpen={isSourcePopupOpen}
          onRequestClose={closeSourcePopup}
          className="fixed inset-0 mx-auto my-8 max-w-lg outline-none"
          overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
        >
          {sourcePopupInfo && (
            <SourcePopup
              title={sourcePopupInfo.title}
              authors={sourcePopupInfo.authors}
              publicationDate={sourcePopupInfo.publicationDate}
              publicationType={sourcePopupInfo.publicationType}
              abstract={sourcePopupInfo.abstract}
              pmid={sourcePopupInfo.pmid}
              isSourceCollapsed={isSourceCollapsed} // Pass the state
            />
          )}
        </Modal>
      </div>
    </Router>
  );
}

export default App;
